import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import {
    SystemObjectT,
    SystemObjectType,
} from '../../../../../services/systemObjects/types';
import { getSystemObjects } from '../../../../../services/systemObjects';
import {
    createSupportRequest,
    createSupportRequestFromPreRequest,
    deleteSupportRequestSystemAttachment,
    getLogs,
    getRequestsStatistics,
    getSupportRequest,
    getSupportRequestsWithFilters,
    updateSupportRequest,
    updateSupportRequestStatus,
} from '../../../../../services/crm';
import { EmployeeGroupClient } from '../../../../../services/accounts/models';
import {
    SupportRequestPaidType,
    SupportRequestPriority,
    SupportRequestsChatFilter,
    SupportRequestsCreateSupportRequestRequest,
    SupportRequestsCreateFromPreSupportRequestRequest,
    SupportRequestsGetSupportRequestListRequest,
    SupportRequestsGetSupportRequestRequest,
    SupportRequestsGetSupportRequestsStatisticsRequest,
    SupportRequestsUpdateSupportRequestRequest,
    SupportRequestsUpdateSupportRequestStatusRequest,
} from 'services/crm/requestsResponses';
import { getEmployees } from 'services/accounts';
import { SupportRequestClient } from 'services/crm/models';
import { SystemObject } from 'services/systemObjects/models';
import { SupportRequestLog, SupportRequestStatus } from 'services/crm/types';
import { AttachmentClient } from 'services/objectStorage/models';
import moment from 'moment/moment';
import { FlatInfo } from 'services/flatOwners/models';
import { getUploadLink } from '../../../../../services/objectStorage';
import { ObjectStorageGetUploadLinkRequest } from '../../../../../services/objectStorage/requestsResponses';
import rootStore from '../../../../../stores';
import { DateRange } from 'rsuite/DateRangePicker';

export type EditDialogType = 'COMMON' | 'APPLICANT';
export type CreateDialogType = 'CREATE';

interface RequestsFilter {
    name: string;
    label: string;
    checked: boolean;
    length: number | null;
}

export class CRMPageStore {
    constructor() {
        makeAutoObservable(this);
    }

    performRedirectToMain = false;

    allSupportRequests: SupportRequestClient[] = [];

    selectedSupportRequest: SupportRequestClient = null;

    housesInRequestOrgBranch: SystemObject[] = [];

    flatsByHouseInRequestOrgBranch: { [houseId: number]: SystemObject[] } = {};

    requestHistory: SupportRequestLog[] = [];

    dialogOpen: EditDialogType | CreateDialogType = null;

    orgBranchEmployees: { [key: number]: EmployeeGroupClient } = {};

    attachments: AttachmentClient[] = [];
    links: { link: string; fileName: string; fileId: string }[] = [];

    attachmentModal = false;

    currentAttachmentLink: string;

    currentExecutorId: string = 'all';
    currentHouseId: number = null;
    houses: {
        [s: string]: SystemObjectT[] | null;
    } = {};
    id2SystemObjectMap: { [s: string]: SystemObjectT } = {};
    executorsStatistics: { [key: string]: { [key: string]: number } };
    housesStatistics: { [key: string]: { [key: string]: number } };
    statusesStatistics: { [key: string]: number };
    prioritiesStatistics: { [key: string]: number };
    paidStatistics: { [key: string]: number };
    chatStatistics: { [key: string]: number };
    typeStatistics: { [key: string]: number };
    loading = true;
    total: number;

    /* FILTERS BLOCK */

    filters: RequestsFilter[] = [
        {
            name: 'NEW',
            label: 'Новая',
            checked: true,
            length: null,
        },
        {
            name: 'IN_PROGRESS',
            label: 'В работе',
            checked: true,
            length: null,
        },
        {
            name: 'COMPLETED',
            label: 'На проверке',
            checked: true,
            length: null,
        },
        {
            name: 'RESOLVED',
            label: 'Выполнена',
            checked: true,
            length: null,
        },
        {
            name: 'CANCELED',
            label: 'Отменена',
            checked: true,
            length: null,
        },
        {
            name: 'MOVED',
            label: 'Перенесена',
            checked: true,
            length: null,
        },
        {
            name: 'CRITICAL',
            label: 'Критический',
            checked: false,
            length: null,
        },
        { name: 'IMPORTANT', label: 'Важный', checked: false, length: null },
        { name: 'REGULAR', label: 'Обычный', checked: false, length: null },

        { name: 'FREE', label: 'Бесплатная', checked: false, length: null },
        { name: 'PAID', label: 'Платная', checked: false, length: null },

        {
            name: 'READ_ONLY',
            label: 'Прочитанные',
            checked: false,
            length: null,
        },
        {
            name: 'UNREAD_ONLY',
            label: 'Непрочитанные',
            checked: false,
            length: null,
        },
        {
            name: 'WITHOUT_EXECUTOR',
            label: 'Без исполнителя',
            checked: false,
            length: null,
        },
        {
            name: 'WITHOUT_RESPONSIBLE',
            label: 'Без ответственного',
            checked: false,
            length: null,
        },
        {
            name: 'OUTDATED',
            label: 'Просроченная',
            checked: false,
            length: null,
        },
        {
            name: '7_DAYS',
            label: 'В течение 7 дней',
            checked: false,
            length: null,
        },
        {
            name: '14_DAYS',
            label: 'В течение 14 дней',
            checked: false,
            length: null,
        },
    ];

    // 2nd version

    // currentCreateDate: Date = moment().subtract(14, 'days').startOf('day').toDate();
    currentCreateDate: DateRange | null = null;
    // currentCompleteDate: Date | null = moment().startOf('day').toDate();
    currentCompleteDate: DateRange | null = null;
    // currentDeadline: Date | null = moment().startOf('day').toDate();
    currentDeadline: DateRange | null = null;
    currentStatus: SupportRequestStatus[] = [
        'NEW',
        'IN_PROGRESS',
        'COMPLETED',
        'RESOLVED',
        'MOVED',
        'CANCELED',
    ];
    currentPriority: string = 'all';
    currentPaid: string = 'all';
    currentChatsMessages: string;
    currentTypeId: number;
    flatOwnersListModal = false;
    flatOwnersListModalCallback?: (info?: FlatInfo) => void;
    createNewRequestModal = false;
    viewRequestModal = false;
    currentFlatOwnerInfoSearchText: string;

    currentRequest?: SupportRequestClient;
    currentFlatOwnerInfo: FlatInfo | undefined;
    currentRequestsSearchText: string | null = null;
    currentRequestNumber: string = '';

    updateStatusModal = false;
    newStatus: SupportRequestStatus | null = null;
    editRequestDescriptionModal = false;

    @action
    setCurrentChatMessages(text: string) {
        this.currentChatsMessages = text;
    }

    @action
    setCurrentOwner(text: string) {
        this.currentFlatOwnerInfoSearchText = text;
    }

    @action
    setCurrentRequestNumber(value: string) {
        this.currentRequestNumber = value;
    }

    @action
    setCurrentSearchText(text: string) {
        this.currentFlatOwnerInfoSearchText = text;
    }

    @action
    setCurrentCreateDate(date: DateRange | null) {
        this.currentCreateDate = date;
    }

    @action
    setCurrentCompleteDate(date: DateRange | null) {
        this.currentCompleteDate = date;
    }

    @action
    setCurrentDeadline(date: DateRange | null) {
        this.currentDeadline = date;
    }

    @action
    setCurrentPaid(paid: string) {
        this.currentPaid = paid;
    }

    @action
    setCurrentSupportRequestType(typeId: number) {
        this.currentTypeId = typeId;
    }

    @action
    setCurrentRequestsSearchText(text: string | null) {
        if (text && text.trim().length == 0) {
            this.currentRequestsSearchText = null;
        } else {
            this.currentRequestsSearchText = text;
        }
    }

    @action
    setCurrentFlatOwnerInfo(flatInfo: FlatInfo | undefined) {
        this.currentFlatOwnerInfo = flatInfo;
    }

    @action
    openFlatOwnersModal(callback?: (info: FlatInfo) => void) {
        this.flatOwnersListModal = true;
        this.flatOwnersListModalCallback = callback;
    }

    @action
    closeFlatOwnersModal() {
        this.flatOwnersListModal = false;
        this.flatOwnersListModalCallback = undefined;
    }

    @action
    setCreateNewRequestModal(value: boolean) {
        this.createNewRequestModal = value;
    }

    @action
    setViewRequestModal(value: boolean, currentRequest?: SupportRequestClient) {
        this.viewRequestModal = value;
        this.currentRequest = currentRequest;
        if (currentRequest) {
            document.body.style.overflow = 'hidden';
            this._getHistory();
        } else {
            document.body.style.overflow = 'scroll';
        }
    }

    @action
    setOpenUpdateStatusModal(
        isOpen: boolean,
        newStatus: SupportRequestStatus = null,
    ) {
        this.updateStatusModal = isOpen;
        this.newStatus = newStatus;
    }

    @computed
    getFastFilters() {
        return this.filters.filter((f) =>
            [
                'NEW',
                'WITHOUT_EXECUTOR',
                'WITHOUT_RESPONSIBLE',
                'OUTDATED',
            ].includes(f.name),
        );
    }

    @computed
    getPriorityFilters() {
        return this.filters.filter((f) =>
            ['REGULAR', 'IMPORTANT', 'CRITICAL', 'ALL_PRIORITIES'].includes(
                f.name,
            ),
        );
    }

    @computed
    getStatusFilters() {
        return this.filters.filter((f) =>
            [
                'NEW',
                'IN_PROGRESS',
                'COMPLETED',
                'RESOLVED',
                'CANCELED',
                'MOVED',
            ].includes(f.name),
        );
    }

    @computed
    getResponsibleFilters() {
        return this.filters.filter((f) =>
            ['WITHOUT_RESPONSIBLE'].includes(f.name),
        );
    }

    @computed
    getChatFilters() {
        return this.filters.filter((f) =>
            ['READ_ONLY', 'UNREAD_ONLY'].includes(f.name),
        );
    }

    @computed
    getDateFilters() {
        return this.filters.filter((f) =>
            ['OUTDATED', '7_DAYS', '14_DAYS'].includes(f.name),
        );
    }

    @computed
    getPaidFilters() {
        return this.filters.filter((f) => ['FREE', 'PAID'].includes(f.name));
    }

    async getOrderUploadLink(params: ObjectStorageGetUploadLinkRequest) {
        const res = await getUploadLink(params);

        if (res.executionStatus == 'Finished') {
            return res;
        } else {
            return null;
        }
    }

    setEditRequestDescriptionModal(value: boolean) {
        this.editRequestDescriptionModal = value;
    }

    async applyFilters(newFilters: string[], orgBranchId: number) {
        const dateFilters = ['OUTDATED', '7_DAYS', '14_DAYS'];
        const dateFilterPresent = newFilters.some((f) =>
            dateFilters.includes(f),
        );
        runInAction(() => {
            this.filters = this.filters.map((f) => ({
                ...f,
                checked: newFilters.includes(f.name)
                    ? !f.checked
                    : dateFilterPresent && dateFilters.includes(f.name)
                    ? false
                    : f.checked,
            }));
        });
        await this.getRequestsStatistics([orgBranchId]);
        await this.getSupportRequestsList([orgBranchId], 0, 20);
    }

    deleteAttachment(attachment: { filename: string; fileId: string }) {
        const filter = this.attachments.filter((item) => {
            return item.fileId !== attachment.fileId;
        });
        this.attachments = filter;
    }

    /* REQUESTS MANIPULATIONS */

    async createSupportRequest(
        params: SupportRequestsCreateSupportRequestRequest,
        orgBranchId: number,
    ) {
        const res = await createSupportRequest(params);
        if (res.executionStatus === 'Finished') {
            this.closeDialog();
            await this.getSupportRequestsList([orgBranchId], 0, 0);
            this.attachments = [];
            return true;
        } else if (res.executionStatus === 'Failed') {
            this.attachments = [];
            return false;
        }
    }

    async createSupportRequestFromPreSupport(
        params: SupportRequestsCreateFromPreSupportRequestRequest,
    ) {
        const res = await createSupportRequestFromPreRequest({
            ...params,
        });
        if (res.executionStatus === 'Finished') {
            this.closeDialog();
            this.attachments = [];
            return true;
        } else if (res.executionStatus === 'Failed') {
            this.attachments = [];
            return false;
        }
    }

    async getSupportRequest(params: SupportRequestsGetSupportRequestRequest) {
        const res = await getSupportRequest(params);
        runInAction(() => {
            this.selectedSupportRequest = res?.supportRequest || null;
            this.currentRequest = res?.supportRequest || null;
        });
        await this._getHistory();
    }

    isSingleRequestPage() {
        return !!this.selectedSupportRequest;
    }

    deselectRequest() {
        runInAction(() => {
            this.selectedSupportRequest = null;
        });
    }

    getDownloadFiltersParams(orgBranchIds: number[]) {
        return {
            chatFilter: this.currentChatsMessages
                ? this.currentChatsMessages
                : null,
            completeByToDate: this._completeByToDateToParams(),
            completeByAfterDate: this._completeByAfterDateToParams(),
            dispatchers: this._responsibleToParams(),
            executors: this._executorsToParams(),
            statuses: this._statusesToParams(),
            priorities: this._prioritiesToParams(),
            orgBranchIds: orgBranchIds,
            houseIds: this.currentHouseId ? [this.currentHouseId] : [],
            strTextSupportRequest: this.currentRequestsSearchText
                ? this.currentRequestsSearchText
                : null,
            fromDateStr: this.currentCreateDate?.[0],
            toDateStr: this.currentCreateDate?.[1],
            startAtFromDateStr: this.currentCompleteDate?.[0],
            startAtToDateStr: this.currentCompleteDate?.[1],
            supportRequestPaidTypeFilter: this._paidToParams(),
            supportRequestTypeIdFilter: this.currentTypeId
                ? this.currentTypeId
                : null,
            flatIds: this.currentFlatOwnerInfo
                ? [this.currentFlatOwnerInfo.flatId]
                : [],
        };
    }

    async getSupportRequestsList(
        orgBranchIds: number[],
        skip: number,
        limit: number,
    ) {
        const params: SupportRequestsGetSupportRequestListRequest = {
            chatFilter: this.currentChatsMessages
                ? this.currentChatsMessages
                : null,

            dispatchers: this._responsibleToParams(),
            executors: this._executorsToParams(),
            statuses: this._statusesToParams(),
            priorities: this._prioritiesToParams(),
            orgBranchIds: orgBranchIds,
            houseIds: this.currentHouseId ? [this.currentHouseId] : [],
            strTextSupportRequest: this.currentRequestsSearchText
                ? this.currentRequestsSearchText
                : null,

            supportRequestPaidTypeFilter: this._paidToParams(),
            supportRequestTypeIdFilter: this.currentTypeId
                ? this.currentTypeId
                : null,
            completeByToDate: this.currentDeadline?.[1],
            completeByAfterDate: this.currentDeadline?.[0],
            startAtFilter: {
                fromDate: this.currentCompleteDate?.[0],
                toDate: this.currentCompleteDate?.[1],
            },
            supportRequestDateFilter: {
                fromDate: this.currentCreateDate?.[0],
                toDate: this.currentCreateDate?.[1],
            },
            flatIds: this.currentFlatOwnerInfo
                ? [this.currentFlatOwnerInfo.flatId]
                : [],
            numbers: this.currentRequestNumber
                ? [parseInt(this.currentRequestNumber)]
                : [],
            skip: skip,
            limit: limit,
        };
        await this._getSupportRequestsWithFilters(params);
    }

    @action
    setTotal(total: number) {
        this.total = total;
    }

    async getRequestsStatistics(orgBranchIds: number[]) {
        const params: SupportRequestsGetSupportRequestsStatisticsRequest = {
            chatFilter: this.currentChatsMessages
                ? this.currentChatsMessages
                : null,
            dispatchers: this._responsibleToParams(),
            executors: this._executorsToParams(),
            statuses: this._statusesToParams(),
            priorities: this._prioritiesToParams(),
            orgBranchIds: orgBranchIds,
            houseIds: this.currentHouseId ? [this.currentHouseId] : [],
            strTextSupportRequest: this.currentRequestsSearchText
                ? this.currentRequestsSearchText
                : null,

            supportRequestPaidTypeFilter: this._paidToParams(),
            supportRequestTypeIdFilter: this.currentTypeId
                ? this.currentTypeId
                : null,
            flatIds: this.currentFlatOwnerInfo
                ? [this.currentFlatOwnerInfo.flatId]
                : [],
            completeByToDate: this.currentDeadline?.[1],
            completeByAfterDate: this.currentDeadline?.[0],
            startAtFilter: {
                fromDate: this.currentCompleteDate?.[0],
                toDate: this.currentCompleteDate?.[1],
            },
            supportRequestDateFilter: {
                fromDate: this.currentCreateDate?.[0],
                toDate: this.currentCreateDate?.[1],
            },
            numbers: this.currentRequestNumber
                ? [parseInt(this.currentRequestNumber)]
                : [],
        };
        await this._getSupportRequestsStatistics(params);
    }

    @action
    async updateStatus(
        newStatus: SupportRequestStatus,
        comment: string | null = null,
    ) {
        return await this._updateSupportRequestStatus({
            attachments: null,
            comment: comment,
            newStatus: newStatus,
            supportRequestId: this.currentRequest.id,
        });
    }

    async removeSystemAttachment(fileId: string, supportRequestId: number) {
        const res = await deleteSupportRequestSystemAttachment({
            fileId,
            supportRequestId,
        });

        if (res) {
            await this._refreshSupportRequest();
            return true;
        } else {
            return false;
        }
    }

    async updateSupportRequest(
        params: SupportRequestsUpdateSupportRequestRequest,
    ) {
        const res = await updateSupportRequest(params);
        if (res.data.executionStatus === 'Finished') {
            await this._refreshSupportRequest();
            this.closeDialog();
            return true;
        } else if (res.data.executionStatus === 'Failed') {
            return false;
        }
    }

    startRedirect() {
        runInAction(() => {
            this.performRedirectToMain = true;
        });
    }

    finishRedirect() {
        runInAction(() => {
            this.performRedirectToMain = false;
        });
    }

    /* DIALOG */

    openDialog(dialogType: EditDialogType | CreateDialogType) {
        runInAction(() => {
            this.dialogOpen = dialogType;
        });
    }

    closeDialog() {
        runInAction(() => {
            this.dialogOpen = null;
        });
    }

    async getHousesWithFlatsForRequest(orgBranchId = null) {
        const res = await getSystemObjects({
            types: [SystemObjectType.House, SystemObjectType.Flat],
            organizationBranchesIds: [
                orgBranchId === null
                    ? this.selectedSupportRequest.orgBranchId
                    : orgBranchId,
            ],
        });
        runInAction(() => {
            this.housesInRequestOrgBranch =
                res?.systemObjectsByParentId['null'];
            delete res?.systemObjectsByParentId['null'];
            this.flatsByHouseInRequestOrgBranch =
                res?.systemObjectsByParentId || {};
        });
    }

    async getEmployees(orgBranchId) {
        const res = await getEmployees({
            adminOrgBranchIds: [orgBranchId],
            dispatcherOrgBranchIds: [orgBranchId],
            executorOrgBranchIds: [orgBranchId],
        });

        runInAction(() => {
            this.orgBranchEmployees = res?.orgBranchEmployees;
        });
    }

    async getSystemObjects(orgBranchIds: number[]) {
        const response = await getSystemObjects({
            types: [SystemObjectType.House],
            organizationBranchesIds: orgBranchIds,
        });
        runInAction(() => {
            const map = {};
            Object.entries(response.systemObjectsByParentId).forEach((arr) => {
                arr[1].forEach((item) => {
                    map[item.id] = item;
                });
            });
            this.houses = response.systemObjectsByParentId;
            this.id2SystemObjectMap = map;
        });
    }

    @action
    openAttachmentModal(link: string) {
        this.currentAttachmentLink = link;
        this.attachmentModal = true;
    }

    @action
    closeAttachmentModal() {
        this.currentAttachmentLink = null;
        this.attachmentModal = false;
    }

    @action
    removeAttachment(name?: string) {
        if (!name) {
            return;
        }
        this.attachments = this.attachments.filter(
            (attachment) => attachment.filename !== name,
        );
    }

    @action
    removeLink(name?: string) {
        if (!name) {
            return;
        }
        this.links = this.links.filter((link) => link.fileName !== name);
    }

    @action
    setExecutorId(id: string) {
        if (id !== 'noExec') {
            const withoutExec = this.filters.filter(
                (i) => i.name === 'WITHOUT_EXECUTOR',
            )[0];
            withoutExec.checked = false;
        } else {
            const withoutExec = this.filters.filter(
                (i) => i.name === 'WITHOUT_EXECUTOR',
            )[0];
            withoutExec.checked = true;
        }
        this.currentExecutorId = id;
    }

    @action
    setHouseId(id: number) {
        this.currentHouseId = id;
    }

    @action
    setRequestStatus(status: SupportRequestStatus[]) {
        if (!status.length) {
            rootStore.generalStore.showSnackbar(
                'Необходимо выбрать хотя бы один фильтр для статуса',
            );
            return;
        } else {
            this.currentStatus = status;
        }
    }

    @action
    setRequestPriority(priority: string) {
        this.currentPriority = priority;
    }

    @action
    renderRequestOldVersion() {
        this.setRequestStatus(undefined);
    }

    /* PRIVATE */

    private async _refreshSupportRequest() {
        await this.getSupportRequest({
            supportRequestId: this.currentRequest.id,
        });
    }

    private _completeByToDateToParams(): Date {
        if (this.currentDeadline) {
            return new Date();
            //return this.currentDeadline;
        } else {
            for (const df of this.getDateFilters()) {
                if (df.checked) {
                    switch (df.name) {
                        case 'OUTDATED':
                            return moment().toDate();
                        case '7_DAYS':
                            return moment().add(7, 'days').toDate();
                        case '14_DAYS':
                            return moment().add(14, 'days').toDate();
                    }
                }
            }
        }
    }

    private _completeByAfterDateToParams(): Date {
        for (const df of this.getDateFilters()) {
            if (df.checked) {
                switch (df.name) {
                    case 'OUTDATED':
                        return null;
                    case '7_DAYS':
                        return moment().toDate();
                    case '14_DAYS':
                        return moment().toDate();
                }
            }
        }
    }

    private _getDayBounds(date: Date | null): {
        fromDate: Date | null;
        toDate: Date | null;
    } {
        return {
            fromDate: date ? moment(date).startOf('day').toDate() : null,
            toDate: date ? moment(date).endOf('day').toDate() : null,
        };
    }

    private _responsibleToParams(): number[] {
        const params = [];
        for (const rf of this.getResponsibleFilters()) {
            if (rf.checked) {
                switch (rf.name) {
                    case 'WITHOUT_RESPONSIBLE':
                        params.push(null);
                        break;
                }
            }
        }
        return params;
    }

    private _prioritiesToParams(): SupportRequestPriority[] {
        const params = [];
        if (this.currentPriority) {
            if (this.currentPriority !== 'all') {
                params.push(this.currentPriority);
            }
        } else {
            for (const pf of this.getPriorityFilters()) {
                if (pf.checked) {
                    switch (pf.name) {
                        case 'REGULAR':
                        case 'IMPORTANT':
                        case 'CRITICAL':
                            params.push(pf.name);
                    }
                }
            }
        }
        return params;
    }

    private _paidToParams(): SupportRequestPaidType {
        let param = null;
        if (this.currentPaid) {
            if (this.currentPaid !== 'all') {
                param = this.currentPaid;
            }
        }
        return param;
    }

    private _chatsToParams(): SupportRequestsChatFilter | null {
        const checkedFilters = this.getChatFilters().filter(
            (chf) => chf.checked,
        );
        if (checkedFilters.length !== 1) {
            return null;
        }
        return checkedFilters[0].name as SupportRequestsChatFilter;
    }

    private _executorsToParams(): number[] {
        if (
            this.filters.filter((i) => i.name === 'WITHOUT_EXECUTOR')[0].checked
        ) {
            this.setExecutorId('noExec');
        } else {
            if (this.currentExecutorId !== 'noExec') {
                this.setExecutorId(this.currentExecutorId);
            } else {
                this.currentExecutorId = 'all';
            }
        }
        switch (this.currentExecutorId) {
            case 'all':
                return [];
            case 'noExec':
                return [null];
            default:
                return [parseInt(this.currentExecutorId)];
        }
    }

    private _statusesToParams(): SupportRequestStatus[] {
        const params = [];
        if (this.currentStatus) {
            params.push(...this.currentStatus);
        } else {
            for (const sf of this.getStatusFilters()) {
                if (sf.checked) {
                    switch (sf.name) {
                        case 'NEW':
                            params.push('NEW');
                            break;
                        case 'IN_PROGRESS':
                            params.push('IN_PROGRESS');
                            break;
                        case 'COMPLETED':
                            params.push('COMPLETED');
                            break;
                        case 'RESOLVED':
                            params.push('RESOLVED');
                            break;
                        case 'CANCELED':
                            params.push('CANCELED');
                            break;
                        case 'MOVED':
                            params.push('MOVED');
                            break;
                    }
                }
            }
            const isOutdatedChosen = this.filters.find(
                (filter) => filter.name === 'OUTDATED',
            )?.checked;

            if (isOutdatedChosen) {
                !params.includes('NEW') ? params.push('NEW') : null;
                !params.includes('IN_PROGRESS')
                    ? params.push('IN_PROGRESS')
                    : null;
            }
        }
        return params;
    }

    private async _getHistory() {
        if (this.currentRequest) {
            const res = await getLogs({
                supportRequestId: this.currentRequest.id,
            });
            runInAction(() => {
                this.requestHistory = res?.logs.reverse() || [];
            });
        }
    }

    private async _updateSupportRequestStatus(
        params: SupportRequestsUpdateSupportRequestStatusRequest,
    ) {
        const res = await updateSupportRequestStatus(params);
        if (res.executionStatus === 'Finished') {
            await this._refreshSupportRequest();
            return true;
        } else if (res.executionStatus === 'Failed') {
            return false;
        }
    }

    private async _updateSupportRequest(
        params: SupportRequestsUpdateSupportRequestRequest,
    ) {
        await updateSupportRequest(params);
        await this._refreshSupportRequest();
    }

    private async _getSupportRequestsWithFilters(
        params: SupportRequestsGetSupportRequestListRequest,
    ) {
        const res = await getSupportRequestsWithFilters(params);
        runInAction(() => {
            this.allSupportRequests = res?.supportRequestList || [];
        });
        this.setTotal(res.total);
        this.loading = false;
    }

    private async _getSupportRequestsStatistics(
        params: SupportRequestsGetSupportRequestsStatisticsRequest,
    ) {
        const res = await getRequestsStatistics(params);
        runInAction(() => {
            this.filters.find((i) => i.name === 'NEW').length =
                res?.statusesStatistics['NEW'] || null;
            this.filters.find((i) => i.name === 'IN_PROGRESS').length =
                res?.statusesStatistics['IN_PROGRESS'] || null;
            this.filters.find((i) => i.name === 'COMPLETED').length =
                res?.statusesStatistics['COMPLETED'] || null;
            this.filters.find((i) => i.name === 'RESOLVED').length =
                res?.statusesStatistics['RESOLVED'] || null;
            this.filters.find((i) => i.name === 'MOVED').length =
                res?.statusesStatistics['MOVED'] || null;
            this.filters.find((i) => i.name === 'WITHOUT_EXECUTOR').length =
                res?.requestsWithoutExecutor || null;
            this.filters.find((i) => i.name === 'WITHOUT_RESPONSIBLE').length =
                res?.requestsWithoutDispatcher || null;
            this.filters.find((i) => i.name === 'READ_ONLY').length =
                res?.chatsStatistics['READ_ONLY'] || null;
            this.filters.find((i) => i.name === 'UNREAD_ONLY').length =
                res?.chatsStatistics['UNREAD_ONLY'] || null;
            this.filters.find((i) => i.name === 'OUTDATED').length =
                res?.overdueRequests || null;
            this.filters.find((i) => i.name === '7_DAYS').length =
                res?.completeIn7DaysRequests || null;
            this.filters.find((i) => i.name === '14_DAYS').length =
                res?.completeIn14DaysRequests || null;
            this.filters.find((i) => i.name === 'REGULAR').length =
                res?.prioritiesStatistics['REGULAR'] || null;
            this.filters.find((i) => i.name === 'IMPORTANT').length =
                res?.prioritiesStatistics['IMPORTANT'] || null;
            this.filters.find((i) => i.name === 'CRITICAL').length =
                res?.prioritiesStatistics['CRITICAL'] || null;
        });
        runInAction(() => {
            this.housesStatistics = res?.housesStatistics;
            this.executorsStatistics = res?.executorsStatistics;
            this.statusesStatistics = res?.statusesStatistics;
            this.prioritiesStatistics = res?.prioritiesStatistics;
            this.paidStatistics = res?.paidTypesStatistics;
            this.chatStatistics = res?.chatsStatistics;
            this.typeStatistics = res?.typesStatistics;
        });
    }
}

const crmPageStore = new CRMPageStore();

export default crmPageStore;
