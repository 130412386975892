import React, { lazy } from 'react';
import { List, Users } from 'react-feather';
import { RouteInfoType, RouteModuleType, RouteType } from '../../types/types';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';
import { AccountRole } from '../../services/accounts/types';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';

const BranchSummaryRedirect = lazy(
    () => import('./pages/BranchSummary/redirect'),
);
const BranchSummary = lazy(() => import('./pages/BranchSummary/index'));

const ReportsPage = lazy(() => import('../maintainer/pages/ReportsPage/index'));
const FlatReceiptsPage = lazy(() => import('./pages/FlatReceipts/index'));
const PaidServiceSettingsPage = lazy(
    () => import('./pages/PaidServices/PaidServicesSettings'),
);

const PaidServiceSchedulePage = lazy(
    () => import('./pages/PaidServices/PaidServicesSchedule'),
);

const PaidServicesListPage = lazy(() => import('./pages/PaidServices/index'));

const CamerasPage = lazy(() => import('./pages/Video/pages/Cameras'));
const CamerasProvidersPage = lazy(
    () => import('./pages/Video/pages/ProvidersList'),
);
const ArchivesPage = lazy(() => import('./pages/Video/pages/Archives'));

const SkudProvidersListPage = lazy(
    () => import('./pages/skud/pages/ProvidersList'),
);
const ViewProviderPage = lazy(() => import('./pages/skud/pages/ViewProvider'));
const EditSigurAccountsPage = lazy(() => import('./pages/Sigur/SigurAccounts'));
const EditSigurAccessPointsPage = lazy(
    () => import('./pages/Sigur/SigurAccessPoints'),
);
const FlatViewDetailsPage = lazy(() => import('./pages/FlatViewDetails/index'));
const FlatUsersPage = lazy(() => import('./pages/FlatUsers/index'));
const FlatOwnersPage = lazy(() => import('./pages/FlatOwners/index'));
const FlatChatsPage = lazy(() => import('./pages/FlatChats/index'));
const CRMRequestPage = lazy(() => import('./pages/crm/pages/SingleRequest'));
const CRMTypesRegistryPage = lazy(
    () => import('./pages/crm/TypesRegistry/index'),
);
const CRMRequestsListPage = lazy(() => import('./pages/crm2/index'));
const CRM2ndOptionRequestListPage = lazy(
    () => import('./pages/crm/pages/RequestsList'),
);
const CRMServicesOrdersPage = lazy(
    () => import('./pages/crm/pages/ServicesOrdersList'),
);
const TelephonyPage = lazy(() => import('./pages/Telephony/index'));
const FlatRequestsPage = lazy(() => import('./pages/FlatRequests/index'));
const NewsAndVotingsPage = lazy(() => import('./pages/NewsPage/index'));
const CleanWaterPage = lazy(() => import('./pages/cleanWater/index'));
const NotificationsPage = lazy(() => import('./pages/Notifications/index'));
const ConnectionLineSummaryPage = lazy(
    () => import('./pages/ConnectionLineSummary/index'),
);
const IntercomList = lazy(
    () => import('./pages/IPIntercom/SipServersListPage/index'),
);
const IntercomSettingsPage = lazy(
    () =>
        import(
            './pages/IPIntercom/editSipServerPage/pages/Settings/SipServerSettings'
        ),
);
const IntercomPanelsPage = lazy(
    () =>
        import(
            './pages/IPIntercom/editSipServerPage/pages/Panels/SipServerPanels'
        ),
);
const IntercomGroupsPage = lazy(
    () => import('./pages/IPIntercom/editSipServerPage/pages/Groups/index'),
);

const ChatListPage = lazy(() => import('./pages/ChatList/index'));

const maintainerRoutes = {
    id: 'maintainer',
    path: '/maintainer',
    name: 'fd',
    icon: <Users />,
    hide: true,
    roles: [AccountRole.Maintainer, AccountRole.Admin, AccountRole.Manager],
    component: <LayoutWithMenu />,
    routes: [
        {
            index: true,
            path: '',
            redirect: '/maintainer/pulse/branch/noBranchSelected',
            exact: true,
            hide: true,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
        },
        {
            path: '3434',
            header: 'АСКУЭ',
            type: RouteType.section,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            childrenModule: [
                RouteModuleType.askue,
                //RouteModuleType.invoices
            ],
        },

        {
            path: '/maintainer/pulse/branch/noBranchSelected',
            icon: <SidebarIcon type={SidebarIconType.pulse} />,
            name: 'Сводка',
            component: <BranchSummaryRedirect />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.askue,
        },
        {
            path: '/maintainer/pulse/branch/:branchId',
            icon: <SidebarIcon type={SidebarIconType.pulse} />,
            name: 'Сводка',
            component: <BranchSummary />,
            type: RouteType.route,
            hide: true,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.askue,
        },

        {
            path: `/maintainer/reports`,
            icon: <SidebarIcon type={SidebarIconType.measurements} />,
            name: 'Показания',
            component: <ReportsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.askue,
        },
        {
            path: `/maintainer/connectionLine/:lineId`,
            icon: <SidebarIcon type={SidebarIconType.measurements} />,
            name: 'Сводка по линии',
            component: <ConnectionLineSummaryPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            hide: true,
            module: RouteModuleType.askue,
        },
        {
            path: `/maintainer/reports/:orgBranch/:houseId/:type`,
            icon: <SidebarIcon type={SidebarIconType.measurements} />,
            name: 'Показания',
            component: <ReportsPage />,
            type: RouteType.route,
            hide: true,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.askue,
        },

        {
            path: '43434',
            header: 'Работа с жителями',
            type: RouteType.section,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            childrenModule: [
                RouteModuleType.crm,
                RouteModuleType.paidServices,
                RouteModuleType.newsAndVotings,
                RouteModuleType.chat,
            ],
        },
        {
            path: '/maintainer/flat-search',
            icon: <SidebarIcon type={SidebarIconType.flatSearch} />,
            name: 'Реестр собственников',
            component: <FlatOwnersPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.crm,
        },
        {
            path: '/maintainer/notifications/:houseId',
            name: 'Уведомления',
            component: <NotificationsPage />,
            type: RouteType.route,
            hide: true,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.askue,
        },
        {
            path: `/maintainer/crm/request/`,
            hide: true,
            component: <CRMRequestPage />,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.crm,
        },
        {
            path: '/maintainer/crm/requests/',
            icon: <SidebarIcon type={SidebarIconType.crm} />,
            name: 'CRM',
            component: <CRMRequestsListPage />,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.crm,
        },
        {
            path: '/maintainer/crm/second-option/requests',
            icon: <SidebarIcon type={SidebarIconType.crm} />,
            hide: true,
            name: 'CRM-2',
            component: <CRM2ndOptionRequestListPage />,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.crm,
        },
        {
            path: '/maintainer/flat-receipts',
            icon: <SidebarIcon type={SidebarIconType.invoices} />,
            name: 'Квитанции',
            component: <FlatReceiptsPage />,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.invoices,
        },
        {
            path: '/maintainer/crm/types-registry',
            icon: <SidebarIcon type={SidebarIconType.crm} />,
            name: 'CRM-реестр заявок',
            component: <CRMTypesRegistryPage />,
            hide: true,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.crm,
        },
        {
            path: '/maintainer/crm/services-orders',
            icon: <SidebarIcon type={SidebarIconType.crm} />,
            name: 'CRM-услуги',
            hide: true,
            component: <CRMServicesOrdersPage />,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            type: RouteType.route,
            module: RouteModuleType.paidServices,
        },
        {
            path: '/maintainer/news',
            icon: <SidebarIcon type={SidebarIconType.newsAndVotings} />,
            name: 'Новости и голосования',
            component: <NewsAndVotingsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.newsAndVotings,
        },
        {
            path: `/maintainer/paid-services/:paidServiceId/settings`,
            icon: <List />,
            name: 'Редактирование услуги',
            hide: true,
            component: <PaidServiceSettingsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.paidServices,
        },
        {
            path: `/maintainer/paid-services/:paidServiceId/schedule`,
            icon: <List />,
            name: 'Расписание для услуги',
            hide: true,
            component: <PaidServiceSchedulePage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.paidServices,
        },
        {
            path: '/maintainer/paid-services',
            icon: <SidebarIcon type={SidebarIconType.paidServices} />,
            name: 'Дополнительные услуги',
            component: <PaidServicesListPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.paidServices,
        },
        {
            path: '/maintainer/chat-list',
            icon: <SidebarIcon type={SidebarIconType.chat} />,
            name: 'Чаты с жителями',
            component: <ChatListPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.chat,
        },
        {
            path: '43434',
            header: 'Инженерные системы',
            type: RouteType.section,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            childrenModule: [
                RouteModuleType.video,
                RouteModuleType.iptelecom,
                RouteModuleType.skud,
                RouteModuleType.cleanWaterManagement,
            ],
        },
        {
            path: '/maintainer/video-cameras',
            icon: <SidebarIcon type={SidebarIconType.video} />,
            name: 'Видеонаблюдение',
            component: <CamerasPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.video,
        },
        {
            path: '/maintainer/cameras/providers',
            icon: <SidebarIcon type={SidebarIconType.video} />,
            name: 'Список провайдеров',
            component: <CamerasProvidersPage />,
            type: RouteType.route,
            hide: true,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.video,
        },
        {
            path: '/maintainer/cameras/archives',
            icon: <SidebarIcon type={SidebarIconType.video} />,
            name: 'Архивы видеонаблюдения',
            component: <ArchivesPage />,
            type: RouteType.route,
            hide: true,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.video,
        },
        {
            path: `/maintainer/skud/:providerId/view`,
            icon: <List />,
            name: 'Редактирование сервера',
            hide: true,
            component: <ViewProviderPage />,
            type: RouteType.route,
            roles: [AccountRole.Admin],
            module: RouteModuleType.skud,
        },
        {
            path: `/maintainer/sigur/:sigurServerId/accounts`,
            icon: <List />,
            name: 'Сотрудники',
            hide: true,
            component: <EditSigurAccountsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.skud,
        },
        {
            path: `/maintainer/sigur/:sigurServerId/AccessPoints`,
            icon: <List />,
            name: 'Доступы сервера SIGUR',
            hide: true,
            component: <EditSigurAccessPointsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.skud,
        },
        /*{
            path: '/maintainer/sigur',
            icon: <SidebarIcon type={SidebarIconType.skud} />,
            name: 'СКУД',
            component: <SigurServersListPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.skud,
        },*/
        {
            path: '/maintainer/skud',
            icon: <SidebarIcon type={SidebarIconType.skud} />,
            name: 'СКУД',
            component: <SkudProvidersListPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.skud,
        },
        {
            path: '/maintainer/clean-water',
            icon: <SidebarIcon type={SidebarIconType.cleanWater} />,
            name: 'Чистая вода',
            component: <CleanWaterPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.cleanWaterManagement,
        },
        {
            path: '/maintainer/sip',
            icon: <SidebarIcon type={SidebarIconType.intercom} />,
            name: 'IP-домофония',
            component: <IntercomList />,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            module: RouteModuleType.iptelecom,
        },
        {
            path: '/maintainer/sip/:sipServerId/settings',
            name: 'Редактирование сервера SIP',
            icon: <Users />,
            hide: true,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            component: <IntercomSettingsPage />,
            module: RouteModuleType.iptelecom,
        },
        {
            path: '/maintainer/sip/:sipServerId/panels',
            name: 'Вызывные панели сервера SIP',
            icon: <Users />,
            hide: true,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            component: <IntercomPanelsPage />,
            module: RouteModuleType.iptelecom,
        },
        {
            path: '/maintainer/sip/:sipServerId/sipGroups',
            name: 'Группы и аккаунты',
            icon: <Users />,
            hide: true,
            type: RouteType.route,
            roles: [
                AccountRole.Admin,
                AccountRole.Maintainer,
                AccountRole.Manager,
            ],
            component: <IntercomGroupsPage />,
            module: RouteModuleType.iptelecom,
        },
        {
            path: `/maintainer/flat/:flatId/view`,
            icon: <List />,
            name: 'Экран квартиры',
            hide: true,
            component: <FlatViewDetailsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.askue,
        },
        {
            path: `/maintainer/flat/:flatId/users`,
            icon: <List />,
            name: 'Жители квартиры',
            hide: true,
            component: <FlatUsersPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.askue,
        },
        {
            path: `/maintainer/flat/:flatId/requests`,
            icon: <List />,
            name: 'История обращений квартиры',
            hide: true,
            component: <FlatRequestsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.crm,
        },
        {
            path: `/maintainer/flat/:flatId/chat`,
            icon: <List />,
            name: 'Чат с жителями квартиры',
            hide: true,
            component: <FlatChatsPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.chat,
        },
        {
            path: `/maintainer/crm/pre-requests`,
            icon: <List />,
            name: 'История предзаявок',
            hide: true,
            component: <TelephonyPage />,
            type: RouteType.route,
            roles: [
                AccountRole.Maintainer,
                AccountRole.Admin,
                AccountRole.Manager,
            ],
            module: RouteModuleType.crm,
        },
    ],
} as RouteInfoType;

export default maintainerRoutes;
