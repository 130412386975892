import {
    AccountsGetAccountsByFlatRequest,
    AccountsGetAccountsByFlatResponse,
    FlatsAddFlatRequest,
    FlatsResponse,
} from './requestsResponses';
import { execute } from '../../apiCommandsExecutor';
import { FlatUsersAPI } from './methods';

export const addNewFlatUser = async (params: FlatsAddFlatRequest) => {
    const res = await execute<FlatsAddFlatRequest, FlatsResponse>(
        FlatUsersAPI.FlatsAddFlat,
        params,
    );

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};

export const getUsersData = async (
    params: AccountsGetAccountsByFlatRequest,
) => {
    const res = await execute<
        AccountsGetAccountsByFlatRequest,
        AccountsGetAccountsByFlatResponse
    >(FlatUsersAPI.AccountsGetAccountsByFlat, {
        flatId: params.flatId,
        roles: params.roles,
    });

    if (res.data.executionStatus === 'Finished') {
        return res.data;
    } else {
        return null;
    }
};
